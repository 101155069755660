<template>
  <div>
    <div v-if="mode === 'customHex8'">
      <div v-for="(item, index) in itemhex8" :key="index" class="color">
        <div
          class="color-rectangle"
          :style="{ 'background-color': item.color }"
        ></div>
        <div v-if="item.min && item.max">{{ item.min }} à {{ item.max }} $</div>
      </div>
    </div>
    <div v-else-if="mode === 'customFsa'">
      <div v-for="(item, index) in itemfsa" :key="index" class="color">
        <div
          class="color-rectangle"
          :style="{ 'background-color': item.color }"
        ></div>
        <div v-if="!item.max">{{ item.min }} $</div>
        <div v-if="item.min && item.max">{{ item.min }} à {{ item.max }} $</div>
      </div>
    </div>
    <div v-else>
      <div v-for="(item, index) in formattedItems" :key="index" class="color">
        <div
          class="color-rectangle"
          :style="{ 'background-color': item.color }"
        ></div>
        <div v-if="item.min && item.max">{{ item.min }} à {{ item.max }}</div>
        <div v-if="!item.max">plus de {{ item.min }}</div>
        <div v-if="!item.min">moins de {{ item.max }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [
        { min: 0, max: 1000, color: 'blue' },
        { min: 1000, max: 2000, color: 'chartreuse' },
        { min: 2000, max: 3000, color: 'tomato' },
        { min: 3000, color: 'hotpink' }
      ]
    },
    format: {
      type: Function,
      default: item => new Intl.NumberFormat('fr-CA').format(item)
    },
    mode: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      itemhex8: [
        { min: '-17.90', max: '1.00', color: '#0A9593' },
        { min: '1.00', max: '5.00', color: '#5EA69E' },
        { min: '5.00', max: '10.00', color: '#A3C0AF' },
        { min: '10.00', max: '20.00', color: '#D5DABE' },
        { min: '20.00', max: '100.00', color: '#DB9299' },
        { min: '100.00', max: '9605.26', color: '#D0587E' }
      ],
      itemfsa: [
        // { min: 0, color: '#ffffff' },
        { min: 'Moins de 0.50', color: '#0A9593' },
        { min: '0.50', max: '1.00', color: '#5EA69E' },
        { min: '1.00', max: '1.50', color: '#abdda4' },
        { min: '1.50', max: '3.00', color: '#D5DABE' },
        { min: '3.00', max: '10.00', color: '#DB9299' },
        { min: '10.00', max: '17.70', color: '#D0587E' }
      ]
    }
  },
  computed: {
    formattedItems() {
      return this.items.map(({ min, max, color }) => ({
        min: min ? this.format(min) : min,
        max: max ? this.format(max) : max,
        color
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.color {
  display: flex;
  align-items: center;
  &-rectangle {
    width: 16px;
    height: 16px;
    border: solid lightgrey 1px;
    border-radius: 5px;
    margin: 6px 8px;
  }
}
</style>
