import { onMounted } from '@vue/composition-api'

export default function({ mapRef, callback }) {
  onMounted(() => {
    const map = mapRef.value.map
    map.on('idle', () => {
      const {
        _ne: { lng: xmax, lat: ymax },
        _sw: { lng: xmin, lat: ymin }
      } = mapRef.value.map.getBounds()
      const bbox = { xmin, ymin, xmax, ymax }
      callback(bbox)
    })
  })
}
