<template>
  <div class="radios-selector">
    <div
      v-for="{ id, sourceConfig, propertyCategory, label } in items"
      :key="id"
      class="radios-selector__item"
    >
      <div v-if="sourceConfig === selectedCategory">
        <input :id="id" v-model="picked" type="radio" :value="id" />
        <label :for="id">{{ label }}</label>
        <div
          v-tooltip.top-center="
            selectedCategory === 'cookit-hexa_8_pop' &&
            propertyCategory === 'tauxpenetration'
              ? tauxpenetrationhex
              : information[propertyCategory]
          "
          class="fas fa-info-circle"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [
        { id: 'one', label: 'One' },
        { id: 'two', label: 'Two' }
      ]
    },
    selected: {
      type: String,
      default: 'one'
    },
    selectedCategory: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      picked: this.selected || 'one',
      information: {
        ventes: 'Montre le total des ventes',
        succes: 'Montre le nombre de dollar dépensé par habitant',
        tauxpenetration:
          'Montre le nombre de clients par habitants, selon le secteur géographique',
        totalventes: 'Montre le total des ventes par segment',
        commandetotale: 'Montre le total des commandes par hexagone'
      },
      tauxpenetrationhex: 'Montre le nombre de clients par population pondéré'
    }
  },
  watch: {
    picked(val) {
      this.$emit('update:selected', val)
    },
    selected(val) {
      this.picked = val
      // console.log('picked: ', this.picked)
    }
  }
}
</script>

<style lang="scss" scoped>
.radios-selector {
  margin: 0 1.5rem;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: left;
  justify-content: left;
  border-bottom: 0.125rem solid lightgrey;
  &__item {
    width: 12.5rem;
    input {
      margin: 0.5rem;
    }
    label {
      width: 100%;
    }
    .fa,
    .far,
    .fas {
      padding: 0.188rem;
    }
  }
}
</style>
