import { watch } from '@vue/composition-api'
import styleBuilder from '@/commons/utils/layer-style/style-builder'
export default function({ mapRef, layerId, viz }) {
  watch(viz, () => {
    const map = mapRef.value.map
    if (viz.value) {
      const { domain, palette, mode, property, scale } = viz.value.config
      const config = styleBuilder.choropleth(
        { property, domain, palette, scale },
        mode
      )
      map.setPaintProperty(layerId, 'fill-color', config.paint['fill-color'])
    }
  })
}
