<template>
  <div class="home">
    <left-panel-layout :is-left-panel-open="mapLoaded" class="app-layout">
      <template v-slot:left-panel>
        <left-panel v-if="mapLoaded">
          <v-select v-model="selectedCity" :options="options"> </v-select>
          <!-- <select v-model="selectedCity">
            <option selected>Toutes</option>
            <option
              v-for="ville in listesmrc"
              :key="ville.nom"
              :value="`${ville.nom}`"
            >
              {{ ville.nom }}
            </option>
          </select> -->
          <br />
          <v-select
            v-model="selectedCategory"
            class="vselectaggr"
            :clearable="false"
            :options="[
              { label: 'Code Postaux 3 chiffres', code: 'cookit-vtes_fsa' },
              { label: 'Agg. largeur ~500m', code: 'cookit-hexa_8_pop' }
            ]"
          >
            <!-- <option value="cookit-vtes_fsa">FSA</option>
            <option value="cookit-hexa_8_pop">HEX 8</option> -->
          </v-select>
          <radio-selector
            v-if="selectedCategory.code === 'cookit-vtes_fsa'"
            :items="layerVizList"
            :selected.sync="selectedViz"
            :selected-category="selectedCategory.code"
          />
          <radio-selector
            v-if="selectedCategory.code === 'cookit-hexa_8_pop'"
            :items="layerVizList"
            :selected.sync="selectedViz"
            :selected-category="selectedCategory.code"
          />
          <div>
            <h3>Sélectionnez par segment:</h3>
            <stats :stats="stats" :selected-segment.sync="selectedSegment" />
          </div>
        </left-panel>
        <div v-else>loading ...</div>
      </template>
      <template v-slot:map>
        <Map
          v-if="$store.state.auth.connected"
          ref="mapRef"
          :layers="mapLayers"
          :visible-layers="visibleLayers"
          :current-viz="currentViz"
          :selected-segment="selectedSegment"
          :selected-city="selectedCity"
          :ville-filtered="villeFiltered"
          :selected-category="selectedCategory.code"
          @loaded="mapLoaded = true"
          @viewportStats="updateStats"
        />
      </template>
    </left-panel-layout>
    <map-legend :open.sync="legendOpen" :viz="currentViz" />
  </div>
</template>

<script>
import LeftPanelLayout from '@anagraph/left-panel-layout'
import Map from '@/components/map'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import RadioSelector from '@/components/radio-selector-viz'
import Legend from '../components/legend'
import sources from '@/data-config/sources'
import vizList from '@/data-config/viz'
import { buildLayer } from '@/commons/utils/layer-helper'
import styleBuilder from '@/commons/utils/layer-style/style-builder'
import Stats from '@/components/stats.vue'
import useDataFetcher from '@/commons/composable/useDataFetcher'
import { fetchData } from '@/commons/utils/data-helper.js'
import LeftPanel from '@/components/left-panel.vue'
import mrc from '../mrc.json'
export default {
  name: 'Home',
  components: {
    LeftPanelLayout,
    Map,
    vSelect,
    MapLegend: Legend,
    RadioSelector,
    Stats,
    LeftPanel
  },
  data() {
    return {
      mapLoaded: false,
      layers: [],
      legendOpen: true,
      defaultLayer: 'cookit-vtes_fsa',
      selectedLayer: null,
      selectedViz: null,
      defaultViz: 'global-sales',
      selectedSegment: null,
      stats: [],
      layerVizList: [],
      currentViz: null,
      selectedCategory: {
        label: 'Code Postaux 3 chiffres',
        code: 'cookit-vtes_fsa'
      },
      selectedidfsa: 'global-sales',
      selectedidhex: 'totalspentsum',
      selectedCity: 'Sélectionner la ville',
      listesmrc: [],
      options: [],
      villeFiltered: []
    }
  },
  computed: {
    currentLayer() {
      return this.layers?.find(i => i.id === this.selectedLayer)
    },
    mapLayers() {
      return this.layers.map(buildLayer)
    },
    visibleLayers() {
      return this.selectedLayer ? [this.selectedLayer] : []
    }
  },
  watch: {
    mapLoaded() {
      this.selectedLayer = this.defaultLayer
      this.selectedViz = this.defaultViz
    },
    // control the layer by default when switching
    // also keep the last property layer selected when switching and when possible
    selectedCategory() {
      const propertyCategory = vizList.find(
        elem => elem.id === this.selectedViz
      )?.propertyCategory
      const vizId = vizList.find(
        elem =>
          elem.propertyCategory === propertyCategory &&
          elem.sourceConfig === this.selectedCategory.code
      )?.id
      if (this.selectedCategory.code === 'cookit-vtes_fsa') {
        this.selectedViz = vizId || 'global-sales'
      } else if (this.selectedCategory.code === 'cookit-hexa_8_pop') {
        this.selectedViz = vizId || 'totalspentsum'
      }
    },
    // watch the city selected in the dropdown
    selectedCity() {
      if (this.selectedCity === null) {
        // si utilisateur clique sur x alors selectionner la ville apparait
        this.selectedCity = 'Sélectionner la ville'
      } else {
        this.villeFiltered = this.listesmrc.filter(
          elem => elem.nom === this.selectedCity
        )
      }
    },
    selectedViz() {
      this.currentViz = vizList?.find(i => i.id === this.selectedViz)
      if (this.currentViz) {
        this.defaultLayer = this.currentViz.sourceConfig
        // this.defaultViz = this.currentViz.id
        this.selectedLayer = this.defaultLayer
        // this.selectedViz = this.defaultViz
      } else {
        console.log('currentViz not found')
      }
    },
    async selectedSegment(segment) {
      if (this.selectedViz === 'global-sales' && segment && segment !== '') {
        const { fetch: fetchClient } = useDataFetcher(
          process.env.VUE_APP_BACKEND_URL,
          null // $store.state.auth.token
        )
        const data = await fetchData(
          fetchClient,
          'fetchStats',
          ['min_max_vtes_fsa_by_xs46'],
          { segment }
        )
        const { min, max } = data[0].value
        this.currentViz = {
          ...this.currentViz,
          config: { ...this.currentViz.config, domain: [min, max] }
        }
      } else {
        this.currentViz = vizList?.find(i => i.id === this.selectedViz)
      }
    }
  },
  mounted() {
    this.layerVizList = vizList.map(
      ({
        id,
        sourceConfig,
        propertyCategory,
        label,
        config: { property, domain, palette, mode, scale }
      }) => {
        return {
          id,
          sourceConfig,
          propertyCategory,
          label,
          config: styleBuilder.choropleth(
            { property, domain, palette, scale },
            mode
          )
        }
      }
    )
    // getting necessary params for making zoom when city is chosen
    this.listesmrc = mrc.map(villes => {
      return {
        nom: villes.dr_name,
        xmin: villes.xmin,
        ymin: villes.ymin,
        xmax: villes.xmax,
        ymax: villes.ymax
      }
    })
    // populating the options value to use in vue select
    this.options = this.listesmrc.map(name => name.nom)
    this.options.unshift('Sélectionner la ville')
    const layers = sources.map(source => ({
      ...source,
      styleConfig: vizList.find(viz => viz.id === this.defaultViz).config
    }))
    this.layers = layers
    this.selectedLayer = null
  },
  methods: {
    toMapLayers(layers) {
      return layers.map(buildLayer)
    },
    updateStats(stats) {
      this.stats = stats
    }
  }
}
</script>
<style lang="scss">
.panel-content__body {
  height: 37.5rem;
}
select {
  width: 8rem;
}
.vselectaggr {
  margin-bottom: 0.75rem;
}
</style>
