import { fillColorExpressionBuilder } from './choropleth'
function buildChoropleth(
  { property, domain, palette, scale },
  mode = 'continuous',
  opacity = 0.75
) {
  return {
    paint: {
      'fill-color': fillColorExpressionBuilder[mode]({
        property,
        domain,
        palette,
        scale
      }),
      'fill-opacity': opacity
    }
  }
}
export default {
  choropleth: buildChoropleth
}
