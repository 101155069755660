export const buildRequests = (params, sqlFunctions) =>
  sqlFunctions.map(sqlFunction => ({
    sqlFunction,
    params
  }))

export const buildSingleStatResultDisplay = data =>
  data.map(({ sqlFunction, result }) => ({
    id: sqlFunction,
    value: result?.value
  }))

export const buildTypologyStatsResultDisplay = data =>
  data.map(({ sqlFunction, result }) => ({
    id: sqlFunction,
    value: result
  }))

export const fetchData = async (fetch, endpoint, sqlFunctions, params) => {
  const requests = buildRequests(params, sqlFunctions)
  const result = await fetch(endpoint, { requests })
  return result.data.map(({ sqlFunction, result }) => ({
    id: sqlFunction,
    value: result?.value || result
  }))
}
