import { scaleQuantize, scaleLinear } from 'd3-scale'
import colors from 'cartocolor'
export function getColors(palette) {
  return Array.isArray(palette) ? palette : colors[palette.name][palette.steps]
}
export function buildDomainSteps(palette, domain) {
  const colors = getColors(palette)
  const scale = scaleQuantize()
    .domain(domain)
    .range(colors)
  const entries = colors.map(c => [scale.invertExtent(c)[0], c])
  return entries
}

export function buildCustomSteps(palette, scale) {
  const colors = getColors(palette)
  const entries = colors.map((c, index) => [scale[index], c])
  return entries
}

export function buildContinousLegendSteps(domain, nbSteps) {
  const scale = scaleLinear()
    .domain(domain)
    .range([0, nbSteps])
  const entries = [...Array(nbSteps).keys()].map(c => scale.invert(c))
  return [...entries.slice(1, entries.length), domain[1]]
}

export function buildDomainLinearFillColor({ property, domain, palette }) {
  return [
    'interpolate',
    ['linear'],
    ['to-number', ['get', property], 0],
    ...buildDomainSteps(palette, domain).flat()
  ]
}

function buildDomainStepsFillColor({ property, domain, palette }) {
  return [
    'step',
    ['to-number', ['get', property], 0],
    '#ffffff',
    ...buildDomainSteps(palette, domain).flat()
  ]
}

function buildCustomStepsFillColor({ property, palette, scale }) {
  return [
    'step',
    ['to-number', ['get', property], 0],
    '#ffffff',
    ...buildCustomSteps(palette, scale).flat()
  ]
}

export function buildCustomFillColorHex8({ property }) {
  return [
    'step',
    ['to-number', ['get', property], 0],
    '#ffffff',
    -17.9,
    '#0A9593',
    1,
    '#5EA69E',
    5,
    '#A3C0AF',
    10,
    '#D5DABE',
    20,
    '#DB9299',
    100,
    '#D0587E',
    9605.26,
    '#D0587E'
  ]
}

export function buildCustomFillColorFsa({ property }) {
  return [
    'step',
    ['to-number', ['get', property], 0],
    '#ffffff',
    0.00047,
    '#0A9593',
    0.5,
    '#5EA69E',
    1,
    '#abdda4',
    1.5,
    '#D5DABE',
    3,
    '#DB9299',
    10,
    '#f91c20',
    17.7,
    '#D0587E'
  ]
}
// #0A9593
// #5EA69E
// #A3C0AF
// #D5DABE
// #DB9299
// #D0587E

export const fillColorExpressionBuilder = {
  discrete: buildDomainStepsFillColor,
  continuous: buildDomainLinearFillColor,
  customDiscrete: buildCustomStepsFillColor,
  customHex8: buildCustomFillColorHex8,
  customFsa: buildCustomFillColorFsa
}
