export default [
  {
    id: 'global-sales',
    sourceConfig: 'cookit-vtes_fsa',
    propertyCategory: 'ventes',
    label: 'Ventes globales',
    legendLabel: 'Total des ventes',
    type: 'chloropleth',
    config: {
      mode: 'continuous',
      property: 'vtes_fsa',
      domain: [0, 316358],
      palette: { name: 'TealRose', steps: 3 },
      format: item =>
        new Intl.NumberFormat('fr-CA').format(item.toFixed(0)) + ' $'
    }
  },
  {
    id: 'dollbyhab',
    sourceConfig: 'cookit-vtes_fsa',
    propertyCategory: 'succes',
    label: 'Taux de succès',
    legendLabel: 'Montant dépensé par habitant',
    type: 'chloropleth',
    config: {
      mode: 'customFsa',
      property: 'dollbyhab',
      domain: [0, 17.66],
      palette: { name: 'TealRose', steps: 7 },
      format: item =>
        new Intl.NumberFormat('fr-CA').format(item.toFixed(2)) + ' $ / hab.'
    }
  },
  {
    id: 'customersbyhab',
    sourceConfig: 'cookit-vtes_fsa',
    propertyCategory: 'tauxpenetration',
    label: 'Taux de pénétration',
    legendLabel: 'Nombre de clients pour 1000 habitants',
    type: 'chloropleth',
    config: {
      mode: 'continuous',
      property: 'customersbyhab',
      domain: [0, 18],
      palette: { name: 'TealRose', steps: 7 },
      format: item => new Intl.NumberFormat('fr-CA').format(item.toFixed(2))
    }
  },
  // {
  //   id: 'segments',
  //   sourceConfig: 'cookit-vtes_fsa',
  //   propertyCategory: 'totalventes',
  //   label: 'AGLAE',
  //   legendLabel: 'Total des ventes pour le segment',
  //   type: 'chloropleth',
  //   config: {
  //     mode: 'continuous',
  //     property: 'vtes_segment',
  //     domain: [0, 3716355],
  //     palette: { name: 'TealRose', steps: 7 },
  //     format: item =>
  //       new Intl.NumberFormat('fr-CA').format(item.toFixed(0)) + ' $'
  //   }
  // },
  {
    id: 'totalspentsum',
    sourceConfig: 'cookit-hexa_8_pop',
    propertyCategory: 'ventes',
    label: 'Ventes globales',
    legendLabel: 'Total des ventes par hexagone 8',
    type: 'chloropleth',
    config: {
      mode: 'continuous',
      property: 'total_spent_sum',
      domain: [1, 137433],
      palette: { name: 'TealRose', steps: 7 },
      format: item =>
        new Intl.NumberFormat('fr-CA').format(item.toFixed(0)) + ' $'
    }
  },
  // {
  //   id: 'totalorderssum',
  //   sourceConfig: 'cookit-hexa_8_pop',
  //   propertyCategory: 'commandetotale',
  //   label: 'Commande totale',
  //   legendLabel: 'Total des commandes par hexagone 8',
  //   type: 'chloropleth',
  //   config: {
  //     mode: 'continuous',
  //     property: 'total_orders_sum',
  //     domain: [1, 2187],
  //     palette: { name: 'TealRose', steps: 7 },
  //     format: item =>
  //       new Intl.NumberFormat('fr-CA').format(item.toFixed(0)) + ' $'
  //   }
  // },
  {
    id: 'dollbyhabhexa8',
    sourceConfig: 'cookit-hexa_8_pop',
    propertyCategory: 'succes',
    label: 'Taux de succès',
    legendLabel: 'Montant dépensé par habitant',
    type: 'chloropleth',
    config: {
      mode: 'customHex8',
      // mode: 'customDiscrete',
      property: 'dollbyhab',
      domain: [0, 20],
      // scale: [-17.9, 1, 5, 10, 20, 100, 9605.26],
      palette: { name: 'TealRose', steps: 7 },
      format: item =>
        new Intl.NumberFormat('fr-CA').format(item.toFixed(0)) + ' $'
    }
  },
  {
    id: 'customersbyhabhex',
    sourceConfig: 'cookit-hexa_8_pop',
    propertyCategory: 'tauxpenetration',
    label: 'Taux de pénétration',
    legendLabel: 'Nombre de clients par habitants',
    type: 'chloropleth',
    config: {
      mode: 'continuous',
      property: 'customersbyhab',
      domain: [0, 1.5],
      palette: { name: 'TealRose', steps: 7 },
      format: item => new Intl.NumberFormat('fr-CA').format(item.toFixed(2))
    }
  }
  // {
  //   id: 'segmentshex',
  //   sourceConfig: 'cookit-hexa_8_pop',
  //   propertyCategory: 'totalventes',
  //   label: 'AGLAE',
  //   legendLabel: 'Total des ventes pour le segment',
  //   type: 'chloropleth',
  //   config: {
  //     mode: 'continuous',
  //     property: 'segment_hexa',
  //     domain: [1, 42],
  //     palette: { name: 'TealRose', steps: 7 },
  //     format: item =>
  //       new Intl.NumberFormat('fr-CA').format(item.toFixed(0)) + ' $'
  //   }
  // }
  // {
  //   id: 'totalspentsum9',
  //   sourceConfig: 'cookit_hexa_9',
  //   label: 'Ventes globales hex 9',
  //   legendLabel: 'Total des ventes par hexagone 9',
  //   type: 'chloropleth',
  //   config: {
  //     mode: 'continuous',
  //     property: 'total_spent_sum',
  //     domain: [1, 42749],
  //     palette: { name: 'TealRose', steps: 7 },
  //     format: item =>
  //       new Intl.NumberFormat('fr-CA').format(item.toFixed(0)) + ' $'
  //   }
  // },
  // {
  //   id: 'totalorderssum9',
  //   sourceConfig: 'cookit_hexa_9',
  //   label: 'Commande totale hex 9',
  //   legendLabel: 'Total des commandes par hexagone 9',
  //   type: 'chloropleth',
  //   config: {
  //     mode: 'continuous',
  //     property: 'total_orders_sum',
  //     domain: [1, 859],
  //     palette: { name: 'TealRose', steps: 7 },
  //     format: item =>
  //       new Intl.NumberFormat('fr-CA').format(item.toFixed(0)) + ' $'
  //   }
  // }
]
