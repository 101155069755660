<template>
  <div class="chloroplet-continuous-legend">
    <div
      class="chloroplet-continuous-legend__gradient"
      :style="{
        backgroundImage: `linear-gradient(${colors.join()})`
      }"
    ></div>
    <div class="chloroplet-continuous-legend__steps">
      <div
        v-for="item in formattedSteps"
        :key="item.id"
        class="chloroplet-continuous-legend__steps__step"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    colors: {
      type: Array,
      default: () => ['#ecda9a,#efc47e,#f3ad6a,#f7945d,#f97b57,#f66356,#ee4d5a']
    },
    steps: {
      type: Array,
      default: () => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    format: {
      type: Function,
      default: item => new Intl.NumberFormat('fr-CA').format(item)
    }
  },
  computed: {
    formattedSteps() {
      return this.steps.map(this.format)
    }
  }
}
</script>

<style lang="scss" scoped>
.chloroplet-continuous-legend {
  position: relative;
  display: flex;
  align-items: center;
  &__gradient {
    position: absolute;
    height: 100%;
    width: 16px;
    margin: 8px;
  }
  &__steps {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 8px;
    margin-left: 48px;
    &__step {
      margin-top: 16px;
      display: flex;
      justify-content: left;
      align-items: flex-end;
      height: 100%;
    }
  }
}
</style>
