<template>
  <legend-panel v-if="viz" :open.sync="legendOpen" class="legend">
    <template v-slot:header>{{ viz.legendLabel }}</template>
    <chloropleth-continuous-legend
      v-if="config.mode === 'continuous'"
      class="legend__continuous"
      :colors="colors"
      :steps="steps"
      :format="config.format"
    />
    <chloropleth-discrete-legend
      v-if="
        config.mode === 'discrete' ||
          config.mode === 'customDiscrete' ||
          config.mode === 'customHex8' ||
          config.mode === 'customFsa'
      "
      class="legend__discrete"
      :items="items"
      :mode="config.mode"
    />
  </legend-panel>
</template>

<script>
import {
  getColors,
  buildContinousLegendSteps,
  buildDomainSteps,
  buildCustomSteps
} from '../commons/utils/layer-style/choropleth.js'
import LegendPanel from '../commons/components/legend/LegendPanel'
import ChloroplethContinuousLegend from '@/commons/components/legend/chloropleth-continuous-legend.vue'
import ChloroplethDiscreteLegend from '@/commons/components/legend/chloropleth-discrete-legend.vue'
export default {
  components: {
    LegendPanel,
    ChloroplethContinuousLegend,
    ChloroplethDiscreteLegend
  },
  props: {
    open: {
      type: Boolean,
      default: true
    },
    viz: {
      type: Object,
      default: () => ({
        id: 'global-sales',
        sourceConfig: 'cookit-vtes_fsa',
        label: 'Ventes globales',
        type: 'chloropleth',
        config: {
          mode: 'continuous',
          property: 'vtes_fsa',
          domain: [24, 316358],
          palette: { name: 'TealRose', steps: 7 },
          format: item => new Intl.NumberFormat('fr-CA').format(item) + ' $'
        }
      })
    }
  },
  computed: {
    legendOpen: {
      get() {
        return this.open
      },
      set(value) {
        this.$emit('update:open', value)
      }
    },
    config() {
      return this.viz.config
    },
    colors() {
      return getColors(this.config.palette)
    },
    steps() {
      return buildContinousLegendSteps(this.config.domain, 5)
    },
    items() {
      const steps =
        this.config.mode === 'customDiscrete'
          ? buildCustomSteps(this.config.palette, this.config.scale)
          : buildDomainSteps(this.config.palette, this.config.domain)
      return steps.map((i, index) => ({
        min: i[0].toFixed(0),
        max: steps[index + 1] ? steps[index + 1][0].toFixed(0) : null,
        color: i[1]
      }))
    }
  }
}
</script>
<style lang="scss">
.legend {
  max-width: 250px;
  .panel {
    min-height: 300px;
  }
}
</style>
