import { onMounted } from '@vue/composition-api'
export default function(mapRef) {
  onMounted(() => {
    const map = mapRef.value.map
    map.on('load', () => {
      map.addSource('rta', {
        type: 'vector',
        tiles: [
          `${process.env.VUE_APP_PROTECTED_TILES_URL}/cookit.cookit_ad_placement_fsa/{z}/{x}/{y}.pbf`
        ]
      })
      map.addLayer({
        id: 'rta',
        type: 'symbol',
        source: 'rta', // reference the data source
        'source-layer': 'cookit.cookit_ad_placement_fsa',
        layout: {
          'text-field': ['get', 'rtaidu'],
          'text-size': {
            stops: [
              [9, 6],
              [11, 12],
              [16, 16],
              [18, 24]
            ]
          },
          'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
          'text-letter-spacing': 0.1,
          'text-max-width': 9,
          'text-transform': 'uppercase'
        },
        paint: {
          'text-color': '#fff',
          'text-halo-color': '#333',
          'text-halo-width': 1,
          'text-halo-blur': 0.25
        },
        before: 'road_minor_case'
      })
    })
  })
}
