var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"radios-selector"},_vm._l((_vm.items),function(ref){
var id = ref.id;
var sourceConfig = ref.sourceConfig;
var propertyCategory = ref.propertyCategory;
var label = ref.label;
return _c('div',{key:id,staticClass:"radios-selector__item"},[(sourceConfig === _vm.selectedCategory)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.picked),expression:"picked"}],attrs:{"id":id,"type":"radio"},domProps:{"value":id,"checked":_vm._q(_vm.picked,id)},on:{"change":function($event){_vm.picked=id}}}),_c('label',{attrs:{"for":id}},[_vm._v(_vm._s(label))]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(
          _vm.selectedCategory === 'cookit-hexa_8_pop' &&
          propertyCategory === 'tauxpenetration'
            ? _vm.tauxpenetrationhex
            : _vm.information[propertyCategory]
        ),expression:"\n          selectedCategory === 'cookit-hexa_8_pop' &&\n          propertyCategory === 'tauxpenetration'\n            ? tauxpenetrationhex\n            : information[propertyCategory]\n        ",modifiers:{"top-center":true}}],staticClass:"fas fa-info-circle"})]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }