export default [
  {
    id: 'cookit-vtes_fsa',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/cookit.cookit_ad_placement_fsa`,
    params: {
      properties: [
        'rtaidu',
        'vtes_fsa',
        'vtes_segment',
        'dollbyhab',
        'customersbyhab',
        'sdr_name',
        'xs46'
      ]
    },
    type: 'fill',
    before: 'road_minor_case'
  },
  {
    id: 'cookit-hexa_8_pop',
    url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/cookit.cookit_hexa_8_pop`,
    params: {
      properties: [
        'rta_id',
        'segment_hexa',
        'total_spent_sum',
        'total_orders_sum',
        'population',
        'dollbyhab',
        'customersbyhab',
        'sdr_name'
      ]
    },
    type: 'fill',
    before: 'road_minor_case'
  }
  // {
  //   id: 'cookit_hexa_9',
  //   url: `${process.env.VUE_APP_PROTECTED_TILES_URL}/cookit.cookit_hexa_9`,
  //   params: {
  //     properties: ['total_spent_sum', 'total_orders_sum', 'sdr_name']
  //   },
  //   type: 'fill',
  //   before: 'road_minor_case'
  // }
]
