<template>
  <div class="stats">
    <div v-for="{ id: statId, value: values } in stats" :key="statId">
      <!-- <h3>{{ statId }}</h3> -->
      <category-widget
        :items="values.map(v => ({ ...v, name: v.id }))"
        :filter-type="'simple-filter'"
        @filter="$emit('update:selectedSegment', $event)"
      />
    </div>
  </div>
</template>

<script>
import CategoryWidget from '@anagraph/category-widget'
export default {
  components: {
    CategoryWidget
  },
  props: {
    stats: {
      type: Array,
      default: () => [
        {
          id: 'getstats',
          value: [
            {
              id: '1',
              value: 110973
            },
            {
              id: '10',
              value: 126284
            },
            {
              id: '11',
              value: 296252
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.stats {
  text-align: left;
  position: relative;
  padding: 8px;
}
</style>
