import styleBuilder from '@/commons/utils/layer-style/style-builder'
export function buildVectorLayerConfig(layerConfig) {
  const sourceLayer = layerConfig.url.split('/').pop()
  const { id, before, style } = layerConfig
  const paramsString = layerConfig.params
    ? Object.entries(layerConfig.params)
        .map(
          ([key, value]) =>
            `${key}=${typeof value !== 'object' ? value : value.join(',')}`
        )
        .join('&')
        .replace(/^/, '?')
    : ''
  const source = {
    type: 'vector',
    tiles: [`${layerConfig.url}/{z}/{x}/{y}.pbf${paramsString}`]
  }
  const layer = {
    id,
    type: layerConfig.type,
    source: id,
    'source-layer': sourceLayer,
    ...style
  }
  return { id, source, layer, before }
}
export const buildLayer = layerConfig => {
  const { mode, property, domain, palette, scale } = layerConfig.styleConfig
  const style = styleBuilder.choropleth(
    { property, domain, palette, scale },
    mode
  )
  const { id, source, layer, before } = buildVectorLayerConfig({
    ...layerConfig,
    style
  })
  return { id, source, layer, before }
}
