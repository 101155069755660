import { onMounted } from '@vue/composition-api'
export default function(mapRef) {
  onMounted(() => {
    const map = mapRef.value.map
    map.on('load', () => {
      map.addSource('limitesdr', {
        type: 'vector',
        tiles: [
          `${process.env.VUE_APP_PROTECTED_TILES_URL}/statscan.demo2016_sdr_light/{z}/{x}/{y}.pbf`
        ]
      })
      map.addLayer({
        id: 'limitesdr',
        type: 'line',
        source: 'limitesdr', // reference the data source
        'source-layer': 'statscan.demo2016_sdr_light',
        paint: {
          'line-color': 'black', // blue color fill
          'line-width': 0.1
        },
        before: 'road_minor_case'
      })
    })
  })
}
