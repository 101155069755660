<template>
  <panel-content>
    <template v-slot:header>
      <h2>Geometric Insights</h2>
    </template>
    <template v-slot:body>
      <slot />
    </template>
    <template v-slot:footer>
      <div class="footer">Une réalisation <b>anagraph</b></div>
    </template>
  </panel-content>
</template>

<script>
import PanelContent from '@/commons/components/panel-content.vue'
export default {
  components: { PanelContent }
}
</script>
