<template>
  <gl-map
    ref="mapRef"
    :init-map-options="options"
    :init-map-position="position"
    @loaded="$emit('loaded')"
  />
</template>

<script>
import { shallowRef, toRefs, watch } from '@vue/composition-api'
import GlMap from '@anagraph/gl-map'
import { useToggleLayers } from '@anagraph/composable-map'
import useMapLayerFilter from '@/commons/composable/useMapLayerFilter'
import useViewportStats from '@/commons/composable/useViewportStats'
// import useStatsNew from '@/commons/composable/useStatsNew'
import { fetchData } from '@/commons/utils/data-helper.js'
import useDataFetcher from '@/commons/composable/useDataFetcher'
import useUpdateMapViz from '@/composable/useUpdateMapViz.js'
import useMapboxPopup from '../commons/composable/useMapboxPopup'
import importLimites from '../commons/composable/importLimites'
import importRta from '../commons/composable/importRta'
// import useUpdateSegmentScale from '@/composable/useUpdateSegmentScale'
const position = {
  zoom: 10.11,
  center: [-73.6365, 45.5358]
}

export default {
  components: {
    GlMap
  },
  props: {
    layers: {
      type: Array,
      default: () => []
    },
    visibleLayers: {
      type: Array,
      default: () => []
    },
    vizList: {
      type: Array,
      default: () => []
    },
    currentViz: {
      type: Object,
      default: () => null
    },
    selectedViz: {
      type: String,
      default: null
    },
    selectedSegment: {
      type: String,
      default: null
    },
    selectedCity: {
      type: String,
      default: null
    },
    villeFiltered: {
      type: Array,
      default: () => []
    },
    selectedCategory: {
      type: String,
      default: null
    }
  },
  setup(props, { emit, root }) {
    const mapRef = shallowRef(null)
    const {
      layers,
      visibleLayers,
      selectedSegment,
      currentViz,
      selectedCity,
      villeFiltered,
      selectedCategory
    } = toRefs(props)
    const transformRequest = (url, resourceType) => {
      if (
        resourceType === 'Tile' &&
        url.startsWith(process.env.VUE_APP_PROTECTED_TILES_URL)
      ) {
        return {
          url: url,
          headers: { Authorization: `Bearer ${root.$store.state.auth.token}` }
        }
      }
    }
    const options = {
      minZoom: 1,
      transformRequest
    }
    // import the limitessdr into the map
    importLimites(mapRef)
    // import the rta symbol into the map
    importRta(mapRef)

    const { fetch: fetchClient } = useDataFetcher(
      process.env.VUE_APP_BACKEND_URL,
      root.$store.state.auth.token
    )
    const viewportChangeCallback = async bbox => {
      const data = await fetchData(
        fetchClient,
        'fetchStats',
        selectedCategory.value === 'cookit-vtes_fsa'
          ? ['sum_vtes_fsa_by_xs46']
          : ['sum_vtes_fsa_by_segment_hexa'],
        { ...bbox, nbSegments: 10 }
      )
      emit('viewportStats', data)
    }
    useViewportStats({ mapRef, callback: viewportChangeCallback })
    useToggleLayers({ mapRef, layers, visibleLayers })
    useMapboxPopup({ mapRef, layer: 'cookit-vtes_fsa' })
    useMapboxPopup({ mapRef, layer: 'cookit-hexa_8_pop' })
    // useMapboxPopup({ mapRef, layer: 'cookit_hexa_9' })
    useUpdateMapViz({
      mapRef,
      layerId: 'cookit-vtes_fsa',
      viz: currentViz
    })
    useUpdateMapViz({
      mapRef,
      layerId: 'cookit-hexa_8_pop',
      viz: currentViz
    })
    // useUpdateMapViz({
    //   mapRef,
    //   layerId: 'cookit_hexa_9',
    //   viz: currentViz
    // })
    useMapLayerFilter({
      mapRef,
      layerId: 'cookit-vtes_fsa',
      selected: selectedSegment,
      targetProperty: 'xs46'
    })
    useMapLayerFilter({
      mapRef,
      layerId: 'cookit-hexa_8_pop',
      selected: selectedSegment,
      targetProperty: 'segment_hexa'
    })
    // watch for select city in dropdown menu
    // zoom into the city selected and apply filter
    watch(
      [villeFiltered, selectedCity, selectedCategory],
      ([filterdcity, seleccity, selectcat]) => {
        if (seleccity !== 'Sélectionner la ville' && seleccity !== null) {
          const lngLatBounds = [
            [filterdcity[0].xmin, filterdcity[0].ymin],
            [filterdcity[0].xmax, filterdcity[0].ymax]
          ]
          mapRef.value.map.fitBounds(lngLatBounds, {
            padding: 100
          })
          const filter =
            seleccity !== 'Sélectionner la ville'
              ? ['match', ['get', 'sdr_name'], [seleccity], true, false]
              : ['all']
          mapRef.value.map.setFilter(selectcat, filter)
          mapRef.value.map.setFilter('limitesdr', filter)
        } else if (seleccity === 'Sélectionner la ville') {
          mapRef.value.map.setFilter(selectcat, null)
          mapRef.value.map.setFilter('limitesdr', null)
        } else if (seleccity === null) {
          mapRef.value.map.setFilter(selectcat, null)
          mapRef.value.map.setFilter('limitesdr', null)
        }
      }
    )
    // useUpdateSegmentScale({
    //   mapRef,
    //   fetchClient,
    //   layerId: 'cookit-vtes_fsa',
    //   selected: selectedSegment,
    //   emit
    // })
    return {
      mapRef,
      options,
      position
    }
  }
}
</script>

<style lang="scss" scoped></style>
